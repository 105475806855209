


























































































































































































































































































































































































































































































// libaries
import moment from "moment";
import { Component, Vue, Watch } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { RawLocation, Route } from "vue-router";
import _ from "lodash";

// services
import {
  SavedSearchService,
  SearchService,
  IUIFilters,
  AccountService,
} from "@/core/services";
import {
  SearchResults,
  ProjectSearchResultModel,
  SearchModel,
  SearchResultContainer,
  Field,
  SavedSearchModel,
  FreeTextSearchType,
  ProjectTrackersModel,
} from "@/core/models";
import { WatcherService } from "@/core/services";
import { routeStack } from "@/core/services/routeStack.service";

// components
import {
  SearchResultItemCpt,
  SearchResultDetailsCpt,
  ResultsBlankSlateCpt,
  ResultsLimitedCpt,
} from "./components";
import { BreadCrumbsCpt } from "@/core/components";
import { HeaderEvents } from "@/layouts/components/HeaderCpt.vue";
import SearchFilterBarCpt, {
  events as SearchBarEvents,
} from "@/modules/search/components/SearchFilterBarCpt.vue";
import MapView, { MapEvents, LocationObject } from "../map/MapView.vue";
import { CreateExportCpt } from "@/modules/export/components";
import { LatLng, LatLngBounds, rectangle } from "leaflet";
import { concat } from "lodash";
import TrackingNotesDialogCpt from "@/modules/tracking/components/TrackingNotesDialogCpt.vue";

@Component({
  components: {
    SearchResultItemCpt: () => Promise.resolve(SearchResultItemCpt),
    SearchResultDetailsCpt: () => Promise.resolve(SearchResultDetailsCpt),
    BreadCrumbsCpt: () => Promise.resolve(BreadCrumbsCpt),
    CreateExportCpt: () => Promise.resolve(CreateExportCpt),
    ResultsBlankSlateCpt: () => Promise.resolve(ResultsBlankSlateCpt),
    ResultsLimitedCpt: () => Promise.resolve(ResultsLimitedCpt),
    SearchFilterBarCpt: () => Promise.resolve(SearchFilterBarCpt),
    MapView: () => Promise.resolve(MapView),
    TrackingNotesDialogCpt: () => Promise.resolve(TrackingNotesDialogCpt),
  },
  async beforeRouteEnter(to, from, next) {
    routeStack.clear();

    await WatcherService.projectList(0);
    const savedState = SearchService.loadProjectSearch();
    if (savedState && savedState.model && savedState.model.results) {
      savedState.model.results.forEach(
        (x) =>
          (x.document.isWatched = WatcherService.isProjectWatched(
            Number(x.document.projectId),
          )),
      );
    }
    const differentFilters =
      Number(to.params.filterId) !== Number(savedState.currentFilter);
    const validSavedFilter = !!savedState.currentFilter;

    if (
      to.params.clearCache === "1" ||
      (differentFilters && validSavedFilter)
    ) {
      to.meta.savedState = {};
    } else {
      to.meta.savedState = savedState;
    }
    next();
  },
  async beforeRouteUpdate(to, from, next) {
    routeStack.clear();

    if (to.params.reload === "false") {
      return next();
    }
    const c = this as ProjectSearchResults;
    if (to && to.params && !to.params.cached) {
      await c.initialize(to.params);
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    const c = this as ProjectSearchResults;
    const cacheObject = {
      model: c.model,
      filters: c.filters,
      builtFilter: c.filter,
      currentFilter: c.currentFilter,
      currentRoute: from,
    };
    SearchService.saveProjectSearch(cacheObject);

    next();
  },
})
export default class ProjectSearchResults extends AppVue {
  searchId: string = "";
  cachedSearch: boolean = false;
  orderBy: string[] = [];
  filters: IUIFilters = SearchService.defaultFilter();
  model = {} as SearchResults<ProjectSearchResultModel>;
  filter = new SearchModel();
  loading = false;
  map = {};
  details: ProjectSearchResultModel = new ProjectSearchResultModel();
  skip = 0;
  currentFilter = 0;
  showSaveDialog = false;
  saveSearchSaveAs: string = "New";
  savedSearchList: any[] = [];
  saveSearchAsExistingID = 0;
  pageSize = 100;
  showMapOnMobile = false;
  renamingId: number = 0;
  newName: string = "";
  showExportDialog = false;
  showFilterDialog = false;
  showMapDialog = false;
  currentBounds: LatLngBounds = {} as LatLngBounds;
  projectTrackers: ProjectTrackersModel[] = [];
  availableUsers = [{ userId: -99, name: "", lastname: "" }];
  selectedProjects: number[] = [];

  get isAllSelected() {
    if (!this.model?.results?.length) return false;
    return this.selectedProjects?.length === this.model?.results?.length;
  }
  async toggleSelectAll() {
    if (this.isAllSelected) {
      this.selectedProjects = [];
    } else {
      if (this.selectedProjects.length > 0) {
        this.selectedProjects = [];
      }
      this.selectedProjects = this.model.results.map((x) =>
        Number(x.document.projectId),
      );
    }
  }
  async addProjectsToUserTrackers(userId: number) {
    if (userId > 0 && this.selectedProjects.length > 0) {
      var res = await WatcherService.trackProjectsForUser(
        this.selectedProjects,
        userId,
      );
      if (res) {
        var tracks = await WatcherService.getAllUsersTrackingSelectedProjects(
          this.selectedProjects,
        );
        tracks.forEach((x) => {
          var pt = this.projectTrackers.find((x) => x.projectId == x.projectId);
          if (!!pt) {
            pt.usersTracking = x.usersTracking;
          } else {
            this.projectTrackers.push(x);
          }
        });
      }
    }
  }
  async handlerUpdateTrackers(projectId: number) {
    var results = await WatcherService.getAllUsersTracking(projectId, 0);
    var pt = this.projectTrackers.find((x) => x.projectId == projectId);
    if (!!pt) {
      pt.usersTracking = results;
    } else {
      this.projectTrackers.push({ projectId, usersTracking: results });
    }
  }
  get buttonClass() {
    return this.$isMobile() ? "mini" : "small";
  }
  projectForgotten({ projectId }: { projectId: string }) {
    this.detailClosing();
    this.model.results = this.model.results.filter(
      (x) => x.document.projectId != projectId,
    );
    this.search(true, undefined, 1, this.model.results.length);
  }
  showMap() {
    this.showMapDialog = true;
  }
  showFilters() {
    this.showFilterDialog = true;
  }
  zoomChange(ev: any) {}

  boundsChange(ev: any) {
    this.currentBounds = ev;
  }
  searchHere() {
    const bounds = rectangle(this.currentBounds);
    this.filters = {
      ...this.filters,
      polyline: (bounds.getLatLngs()[0] as LatLng[])
        .reverse()
        .map((x) => [x.lat, x.lng]),
    };
    this.filtersUpdated();
    this.$eventHub.$emit(SearchBarEvents.SetDefaultFilters, this.filters);
  }
  showDialog() {
    this.buildFilter();
    this.showExportDialog = true;
  }
  get rows() {
    return this.model.results || [];
  }
  get debug() {
    return this.$route.query.debug;
  }
  get exportFilters() {
    return this.filters;
  }
  get IsSelfBuild() {
    const t = AccountService.getSubscriptionType();
    return t.IsSelfBuild === "True";
  }

  get IsMajorProjects() {
    const t = AccountService.getSubscriptionType();
    return t.IsMajorProjects === "True";
  }
  endRename(hideId: number) {
    if (hideId === this.renamingId) {
      this.newName = "";
      this.renamingId = 0;
    }
  }
  startRename(model: SearchModel) {
    this.newName = model.name;
    this.renamingId = model.id;
  }
  async renameSearch(model: any, newName: string) {
    if (model.id === this.renamingId) {
      let res = new SavedSearchModel();

      res = await SavedSearchService.renameSavedSearch(
        this.renamingId,
        this.newName,
      );
      this.$emit("updated", res);
      model.isRenaming = false;
      this.filter.name = res.name;
    }
  }
  mounted() {
    super.updateTitle("Search Results");
    this.$eventHub.$emit(MapEvents.Clear);
    this.$eventHub.$emit(MapEvents.ClearDrawLayer);
    routeStack.addTitle(this.$route, "Search Results");
  }

  unwatchProject(item: any) {
    this.$nextTick(() => {
      item.document.isWatched = false;
    });
  }

  watchProject(item: any) {
    this.$nextTick(() => {
      item.document.isWatched = true;
    });
  }

  getPromoter(item: any) {
    if (!item.companies.length) return "";

    const lookup = _.find(item.companies, { roleID: "8" });
    return lookup ? lookup.name : "";
  }

  async savedSearchTypeChange() {
    if (this.saveSearchSaveAs === "As") {
      this.savedSearchList = await SavedSearchService.list(0);
    }
  }
  mapDialogOpened() {
    this.addMarkers(this.model.results);
    this.$eventHub.$emit(MapEvents.Refresh);
  }
  detailClosing() {
    this.details = new ProjectSearchResultModel();
    this.$eventHub.$emit("closeDetailView");
    this.$eventHub.$emit(MapEvents.Refresh);
  }

  async created() {
    this.$eventHub.$on("MarkerClicked", this.markerClick);
    this.$eventHub.$on(SearchBarEvents.ClearFilters, this.clearFilters);
    this.$eventHub.$on(SearchBarEvents.FilterUpdated, this.filterUpdated);
    this.$eventHub.$on(SearchBarEvents.OrderByUpdated, this.orderByUpdated);
    this.$eventHub.$on(
      HeaderEvents.SearchQueryChanged,
      this.searchQueryChanged,
    );
    this.$eventHub.$on(SearchBarEvents.ToggleMap, this.toggleMap);
    await this.initialize(this.$route.params, this.$route.meta);
  }

  destroyed() {
    this.$eventHub.$off("MarkerClicked", this.markerClick);
    this.$eventHub.$off(SearchBarEvents.ClearFilters, this.clearFilters);
    this.$eventHub.$off(SearchBarEvents.FilterUpdated, this.filterUpdated);
    this.$eventHub.$off(SearchBarEvents.OrderByUpdated, this.orderByUpdated);
    this.$eventHub.$off(SearchBarEvents.ToggleMap, this.toggleMap);
    this.$eventHub.$off(
      HeaderEvents.SearchQueryChanged,
      this.searchQueryChanged,
    );
    this.$eventHub.$off(SearchBarEvents.ToggleMap, this.toggleMap);
  }
  markerClick(item: LocationObject) {}

  searchQueryChanged(form: any) {
    this.filters.query = form.query;
    this.filters.queryType = form.queryType;
    this.filtersUpdated();
  }

  clearFilters(filters: any) {
    this.filters = filters;
    this.filtersUpdated();
  }

  removeFilterFromUrl() {
    const currentFilterId = Number(this.$route.params.filterId);
    const filterId = Number(this.filter.id);
    this.filter.name = "";
    this.filter.id = 0;
    if (!!currentFilterId) {
      this.$router
        .push({
          name: this.$route.name || "",
          params: {
            query: this.filters.query || "",
            queryType: this.filters.queryType.toString(),
            projectsLoaded: "100",
          },
        } as RawLocation)
        .catch(() => {});
    }
  }
  orderByUpdated(orderBy: string[]) {
    if (!!orderBy) {
      this.orderBy = orderBy;
    } else {
      this.orderBy = [];
    }
    this.filtersUpdated();
  }
  filterUpdated(filters: any) {
    this.showFilterDialog = false;
    this.filters = {
      ...filters,
      query: this.filters.query,
      queryType: this.filters.queryType,
    };
    this.filter.name = "";
    this.filter.id = 0;
    this.filtersUpdated();
  }
  setBreadCrumbName() {
    routeStack.push(this.$route);
    routeStack.addTitle(
      this.$route,
      `Search Results (${this.model.totalRecords})`,
    );
  }
  processCache(meta?: any) {
    if (meta && meta.savedState.model && meta.savedState.filters) {
      this.model.compiledSearch = meta.savedState.model.compiledSearch;
      this.model.results = meta.savedState.model.results;
      this.model.facets = meta.savedState.model.facets;
      this.model.totalRecords = meta.savedState.model.totalRecords;
      this.searchId = this.model.searchId;
      this.currentFilter = meta.savedState.currentFilter;
      this.updateUrl(true);
      this.filters = { ...this.filters, ...meta.savedState.filters };
      this.filter = meta.savedState.builtFilter;

      this.addMarkers(this.model.results);
      this.$nextTick(() => {
        this.setBreadCrumbName();
        const selectedItem = this.model.results.find(
          (x) => x.document.selected,
        );
        if (selectedItem) {
          this.$eventHub.$emit("ScrollToResult", {
            resultId: selectedItem.document.projectId,
          });
          // select the result on desktop and mobile
          if (!this.$isMobile()) {
            // automatically open the peek tab only on desktop
            this.selectItem(selectedItem);
          }
        }
        this.$eventHub.$emit(SearchBarEvents.SetDefaultFilters, this.filters);
      });
      return true;
    }
    return false;
  }

  updateUrl(cached: boolean) {
    const params: any = {
      ...this.$route.params,
      projectsLoaded: this.model.results.length.toString(),
      reload: "false",
    };
    const query = {
      ...this.$route.query,
    };
    if (cached) {
      params.cached = "true";
    }
    if (this.currentFilter) {
      params.filterId = this.currentFilter.toString() || "0";
    }
    if (
      Number(params.filterId) !== Number(this.$route.params.filterId) ||
      Number(params.projectsLoaded) !==
        Number(this.$route.params.projectsLoaded)
    ) {
      if (this.$route.params === params) {
        return;
      }
      this.$router
        .push({
          name: this.$route.name || "",
          params,
          query,
        } as RawLocation)
        .catch(() => {});
    }
  }
  async initialize(params: any, meta?: any) {
    this.skip = 0;
    this.$eventHub.$emit(MapEvents.Clear);

    if (!this.processCache(meta)) {
      this.currentFilter = Number(params.filterId);
      if (!!this.currentFilter) {
        this.loading = true; // start the loading now. the search at the end of the road will turn it off
        this.filter = await SavedSearchService.getSingle(this.currentFilter);
        this.filters = SearchService.convertProjectSearchModelToFilters(
          this.filter,
        );
        this.$eventHub.$emit(MapEvents.Clear);

        // setup save search stuff
        this.saveSearchSaveAs = "As";
        this.saveSearchAsExistingID = this.filter.id;
        this.newName = this.filter.name;
        this.$nextTick(() => {
          this.drawAreasOnTheMapIfSaved();
        });

        this.$eventHub.$emit(SearchBarEvents.SetDefaultFilters, this.filters);
      } else {
        this.filters.query = params.query || "";
        this.filters.queryType = params.queryType || FreeTextSearchType.Any;

        this.buildFilter();
      }
      this.$nextTick(() => {
        this.search(false, params.projectLoaded); // search manages the loading status
      });
    } else {
      // if it is a search from cache, update the skip value
      this.skip = this.model.results.length - this.pageSize;
      // and load the tracking users from the cache
      await this.getProjectTrackers();
    }
  }

  drawAreasOnTheMapIfSaved() {
    if (!!this.filters.polyline && this.filters.polyline.length > 0) {
      this.$eventHub.$emit(
        MapEvents.DrawPolylineFromUserInput,
        this.filters.polyline,
      );
    } else if (
      !!this.filters.geoRange &&
      !!this.filters.geoRange.center &&
      !!this.filters.geoRange.range
    ) {
      this.$eventHub.$emit(MapEvents.DrawCircleFromUserInput, {
        center: this.filters.geoRange.center,
        radius: this.filters.geoRange.range,
      });
    }
  }

  filtersUpdated() {
    // coming from a redirect (the table/map duality).
    // do not remove the filter, this is not a "new query".
    if (this.$route.params.keepFilter == "1") {
      this.$route.params.keepFilter = "";
      return;
    }
    this.selectedProjects = [];
    this.skip = 0;
    this.buildFilter();
    this.removeFilterFromUrl();
    this.$eventHub.$emit(MapEvents.Clear);
    this.search(false);
  }

  async search(
    append: boolean,
    projectLoaded?: number,
    pageSize?: number,
    skip?: number,
  ) {
    try {
      this.loading = true;
      this.detailClosing();

      // close all filters?
      this.$eventHub.$emit("FilterDialogOpened", "none");
      if (append) {
        const newResults = await SearchService.search(
          this.filters.query,
          pageSize || this.pageSize,
          skip || this.skip,
          this.filter,
          this.orderBy,
          this.searchId,
        );
        newResults.results.forEach(
          (x) =>
            (x.document.isWatched = WatcherService.isProjectWatched(
              Number(x.document.projectId),
            )),
        );
        newResults.results.forEach((x) => this.model.results.push(x));
        this.addMarkers(newResults.results);
      } else {
        this.model = await SearchService.search(
          this.filters.query,
          projectLoaded || this.pageSize,
          this.skip,
          this.filter,
          this.orderBy,
          this.searchId,
        );
        this.searchId = this.model.searchId;
        this.model.results?.forEach(
          (x) =>
            (x.document.isWatched = WatcherService.isProjectWatched(
              Number(x.document.projectId),
            )),
        );
        this.addMarkers(this.model.results);
        if (this.model.results.length > 0) {
          this.$eventHub.$emit("ScrollToResult", {
            resultId: this.model.results[0].document.projectId,
          });
        }

        await this.getProjectTrackers();
      }
      this.setBreadCrumbName();
    } finally {
      this.loading = false;
    }
  }

  async getProjectTrackers() {
    var projectIds = this.model.results.map((x) =>
      parseInt(x.document.projectId),
    );

    this.availableUsers = await WatcherService.getAllVisibleUsers();
    this.projectTrackers = await WatcherService.getAllUsersTrackingSelectedProjects(
      projectIds,
    );
  }

  addMarkers(results: Array<SearchResultContainer<ProjectSearchResultModel>>) {
    this.$nextTick(() => {
      const markers: Array<SearchResultContainer<
        ProjectSearchResultModel
      >> = results.filter((x) => x.document.location);
      this.$eventHub.$emit(
        MapEvents.AddMarkerCollection,
        markers.map((m: SearchResultContainer<ProjectSearchResultModel>) => ({
          resultId: Number(m.document.projectId),
          data: m.document,
          latLong: {
            lat: m.document.location.latitude,
            lng: m.document.location.longitude,
          },
          zoom: 13,
          pin: true,
          text: m.document.title,
        })),
      );
    });
  }

  selectItem(item: SearchResultContainer<ProjectSearchResultModel>) {
    // unselect selected
    this.model.results
      .filter((x) => x.document.selected)
      .forEach((x) => (x.document.selected = false));
    // select current
    item.document.selected = true;
    this.$eventHub.$emit("openDetailView");
    this.details = item.document;
    super.updateTitle(`${item.document.title}`);

    if (item.document.location) {
      // we show the details
      // then we tell the map to focus, but only on the next render
      // so the map can be recentered correctly - FR
      this.$nextTick(() => {
        this.$eventHub.$emit(MapEvents.Focus, {
          resultId: item.document.projectId,
          data: item.document,
          pin: true,
          zoom: 18,
          latLong: {
            lat: item.document.location.latitude,
            lng: item.document.location.longitude,
          },
        });
      });
    } else {
      this.$nextTick(() => {
        this.$eventHub.$emit(MapEvents.Center);
      });
    }
  }

  loadMore() {
    this.skip = this.skip + this.pageSize;
    this.search(true).then(() => {
      this.updateUrl(false);
    });
  }

  resetFilter() {
    this.filter = {
      id: this.filter.id,
      name: this.filter.name,
      freeText: this.filters.query,
      freeTextType: this.filters.queryType,
      isAlertEnabled: this.filter.isAlertEnabled,
      fields: Array<Field>(),
    } as SearchModel;
  }
  buildFilter() {
    this.resetFilter();
    if (this.$route.query) {
      if (this.$route.query.trackingFilter === "true") {
        this.filters.trackingFilter = "Tracked";
      }
    }
    const results: Field[] = SearchService.buildProjectFilter(
      this.filters.trackingFilter,
      this.filters.geoRange,
      this.filters.value.min,
      this.filters.value.max,
      this.filters.stage,
      this.filters.categories,
      [
        this.filters.dateUpdated,
        this.filters.dateCreated,
        this.filters.tenderDeadlineDate,
        this.filters.finishDate,
        this.filters.projectStartDate,
        this.filters.applicationDate,
        this.filters.decisionDate,
      ],
      this.filters.counties,
      this.filters.isSelfBuild,
      this.filters.roles,
      this.filters.polyline,
      this.filters.fundingType,
      this.filters.constructionType,
      this.filters.planningAuthority,
      this.filters.materials,
      this.filters.floorArea,
      this.filters.numberOfParkingSpaces,
      this.filters.siteArea,
      this.filters.numberOfStoreys,
      this.filters.numberOfUnits,
      this.filters.applicationType,
      this.filters.isFramework,
      this.filters.appealStatusList,
    );
    results.forEach((x) => this.filter.fields.push(x));
  }

  startTour() {
    this.$tours.searchTour.start();
  }

  openSaveDialog() {
    this.newName = this.filter.name;
    this.showSaveDialog = !this.showSaveDialog;
    this.savedSearchTypeChange();
    if (this.saveSearchSaveAs === "New") {
      const nameInput = this.$refs["nameInput"] as Vue;
      const input = nameInput.$refs["input"] as any;
      this.$nextTick(() => {
        input.focus();
      });
    }
  }
  async catchEnter(keyUpEvent: KeyboardEvent) {
    if (keyUpEvent.key === "Enter") {
      await this.save();
    }
  }
  async save() {
    let savedSearch;
    if (this.saveSearchSaveAs === "As") {
      savedSearch = await SavedSearchService.update(
        this.saveSearchAsExistingID,
        this.filter,
      );
    } else {
      this.filter.name = this.newName;
      savedSearch = await SavedSearchService.save(this.filter);
    }
    this.currentFilter = savedSearch.userAzureSearchID;
    this.showSaveDialog = false;
    this.$router
      .push({
        params: {
          filterId: savedSearch.userAzureSearchID.toString(),
        },
      })
      .catch(() => {});

    const messageObj: any = {
      title: "Saved",
      message: "Search saved",
      type: "success",
    };
    this.$notify(messageObj);
  }

  toggleMap(value: boolean) {
    this.showMapOnMobile = value;
    this.$nextTick(() => this.$eventHub.$emit(MapEvents.Refresh));
  }

  get mobileMapClass() {
    return this.showMapOnMobile ? "mobile-show-map" : "";
  }
}
